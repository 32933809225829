export default [
  {
    key: "increasement",
    label: "#",
  },
  {
    key: "betTool",
    label: "field.tool",
  },
  // {
  //   key: "betDate",
  //   label: "field.date",
  // },
  {
    key: "time",
    label: "field.time",
  },
  {
    key: "betAmount",
    label: "field.betAmount",
  },
  {
    key: "stackAmount",
    label: "field.stackAmount",
  },
  {
    key: "payout",
    label: "field.odds",
  },
  {
    key: "taxAmount",
    label: "field.tax",
  },
  // {
  //   key: "betType",
  //   label: "field.betType",
  // },
  {
    key: "selectBet",
    label: "field.bet",
  },
  {
    key: "result",
    label: "field.result",
  },
  {
    key: "payoutAmount",
    label: "field.winLoss",
  },
  {
    key: "paybackAmount",
    label: "field.payback",
  },
  {
    key: "createdAt",
    label: "field.date",
  },
];