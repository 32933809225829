<template>
  <div>
    <b-modal
      id="modal-bet-history"
      hide-footer
      centered
      :title="$t('general.betHistory')"
      size="xl"
      @hide="isShow = false"
    >
      <div>
        <n-search-container
          :active="true"
          :show-header="false"
          @search="search"
          @reset="reset"
          v-if="searchFields.length"
          :loading="loading"
        >
          <n-search-input ref="search" :fields="searchFields" v-model="params">
            <template #period="item">
              <b-form-group
                :label-for="item.field.key"
                :label="$t(item.field.label)"
              >
                <b-form-radio-group
                  :ref="item.field.key"
                  v-if="item.field.type === 'radio'"
                  :id="item.field.key"
                  v-model="params[item.field.key]"
                  buttons
                  button-variant="outline-primary rounded-0"
                  class="flex-wrap"
                >
                  <b-form-radio
                    :value="option.value"
                    v-for="option in item.field.options"
                    :key="option.value"
                  >
                    {{ $t(option.text) }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </template>
          </n-search-input>
        </n-search-container>
        <div>
          <b-row>
            <b-col>
              <label>{{ $t("general.entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>
          <n-table
            @sort-changed="sortChanged"
            :fields="fields"
            :items="items"
            :busy="loading"
            :current-page="params.page"
            :per-page="$store.state.pagination.perPage"
            :total="total"
          >
            <template #cell(betTool)="data">
              <span v-if="data.item.betTool">
                {{
                  $i18n.locale.en == "en"
                    ? data.item.betTool.betToolEn
                    : data.item.betTool.betToolKh
                }}
              </span>
            </template>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt | formatDate }}
            </template>
            <template #cell(time)="data">
              [{{ data.item.betDate | formatDate("DD-MM-YYYY") }}]
              {{ moment(data.item.startTime, "HH:mm").format("HH:mm") }}
              -
              {{ moment(data.item.endTime, "HH:mm").format("HH:mm") }}
            </template>
            <template #cell(betAmount)="data">
              <span v-if="data.item.betType == 2">
                {{ data.item.betAmount | currency(ccy) }}
              </span>
            </template>
            <template #cell(stackAmount)="data">
              {{ data.item.stackAmount | currency(ccy) }}
            </template>
            <template #cell(taxAmount)="data">
              <span class="text-danger">
                {{ data.item.taxAmount | currency(ccy) }}
              </span>
            </template>
            <template #cell(betType)="data">
              <span v-if="data.item.betType == 1">
                {{ $t("general.betOrder") }}
              </span>
              <span v-if="data.item.betType == 2">
                {{ $t("general.confirmBet") }}
              </span>
            </template>
            <template #cell(selectBet)="data">
              <span v-if="data.item.selectBet == 1" class="text-rain">
                {{ $t("field.rain") }}
              </span>
              <span v-if="data.item.selectBet == 2" class="text-norain">
                {{ $t("field.noRain") }}
              </span>
            </template>
            <template #cell(result)="data">
              <span v-if="data.item.result == 1" class="text-rain">
                {{ $t("field.rain") }}
              </span>
              <span v-if="data.item.result == 2" class="text-norain">
                {{ $t("field.noRain") }}
              </span>
            </template>
            <template #cell(payout)="data">
              <span
                :class="{
                  'text-danger': data.item.payout < 0,
                }"
              >
                {{ data.item.payout }}
              </span>
            </template>
            <template #cell(payoutAmount)="data">
              <span
                :class="{
                  'text-positive': data.item.payoutAmount >= 0,
                  'text-danger': data.item.payoutAmount < 0,
                }"
              >
                {{ data.item.payoutAmount | currency(ccy) }}
              </span>
            </template>
            <template #cell(paybackAmount)="data">
              <span
                :class="{
                  'text-positive': data.item.paybackAmount >= 0,
                  'text-danger': data.item.paybackAmount < 0,
                }"
              >
                {{ data.item.paybackAmount | currency(ccy) }}
              </span>
            </template>

            <template #custom-foot>
              <b-tr v-if="!loading">
                <b-th colspan="3"></b-th>
                <b-th class="text-center">
                  <h5 class="font-weight-bold">
                    {{ totalReport.totalBetAmount | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-center">
                  <h5 class="font-weight-bold">
                    {{
                      totalReport.totalStackAmount | currency(totalReport.ccy)
                    }}
                  </h5>
                </b-th>
                <b-th> </b-th>
                <b-th class="text-center">
                  <h5 class="font-weight-bold text-danger">
                    {{ totalReport.totalTaxAmount | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th> </b-th>
                <!-- <b-th> </b-th> -->
                <b-th> </b-th>
                <b-th class="text-center">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-positive': totalReport.totalWinAmount >= 0,
                      'text-danger': totalReport.totalWinAmount < 0,
                    }"
                  >
                    {{ totalReport.totalWinAmount | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-center">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-positive': totalReport.totalPaybackAmount >= 0,
                      'text-danger': totalReport.totalPaybackAmount < 0,
                    }"
                  >
                    {{
                      totalReport.totalPaybackAmount | currency(totalReport.ccy)
                    }}
                  </h5>
                </b-th>
                <b-th></b-th>
              </b-tr>
            </template>
          </n-table>
          <n-pagination
            class="mt-2"
            :total="total"
            :per-page="$store.state.pagination.perPage"
            :page="params.page"
            @change="list"
            ref="pagination"
          ></n-pagination>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BModal,
  BRow,
  BSpinner,
  BTh,
  BTr,
  BButton,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import searchInputs from "./searchInput";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";
import tableFields from "./tableFields";
import { calculateDatesByPeriod } from "@/libs/helper";
import Repository from "@/repositories/RepositoryFactory";
import vSelect from "vue-select";
import moment from "moment";
import NSingleSelect from "@/components/NSingleSelect";

const BetHistoryRepository = Repository.get("betHistory");

export default {
  components: {
    BModal,
    BSpinner,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BTr,
    BTh,
    BRow,
    BCol,
    BButton,
    NFormConfirmation,
    NInput,
    NSearchContainer,
    NSearchInput,
    NTable,
    NPagination,
    NSingleSelect,
    vSelect,
  },
  watch: {
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      ccy: 840,
      loading: false,
      userId: null,
      params: {
        page: 1,
        userId: null,
        startDate: null,
        endDate: null,
        period: null,
      },
      total: 0,
      items: [],
      totalReport: {},
      isShow: false,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      moment,
    };
  },
  created() {},
  methods: {
    show() {
      this.$bvModal.show("modal-bet-history");
      this.isShow = true;
      this.list();
    },
    hide() {
      this.isShow = false;
      this.$bvModal.hide("modal-bet-history");
    },
    changeData(data) {
      this.userId = data.userId;
      this.params = {
        ...this.params,
        ...this.data,
        ...data,
      };
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.params.search = searchText;
      this.getData();
    },
    reset() {
      this.params.userId = null;
      this.$refs.search.reset();
      this.params.page = 1;
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.endDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    getData() {
      if (this.isShow) {
        this.loading = true;
        this.params.searchFields = [...this.searchFields];
        BetHistoryRepository.index({
          ...this.params,
          userId: this.userId,
          lotteryId: this.lotteryId,
        })
          .then((response) => {
            let data = response.data.data;
            this.items = [...data.list];
            this.total = data.total;
            this.getTotal();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getTotal() {
      this.params.searchFields = [...this.searchFields];
      BetHistoryRepository.total({
        ...this.params,
        userId: this.userId,
        lotteryId: this.lotteryId,
      })
        .then((response) => {
          const data = response.data.data;
          this.totalReport = {
            ...data,
          };
        })
        .catch(() => {});
    },
  },
  setup() {
    const searchFields = [...searchInputs];
    let fields = [...tableFields];

    return {
      searchFields,
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
